/*------------- #FOOTER --------------*/

.footer-content {
  padding: 60px 0 40px;
}

.widget--footer .widget-title {
  color: var(--white-color);
}

.sub-footer {
  padding: 20px 15px;
  background-color: #12171b;
  text-align: center;
  font-size: 14px;

  a {
    color: var(--white-color);
    opacity: .5;

    &:hover {
      color: var(--primary-accent-color);
      opacity: 1;
    }
  }
}

/*------------- Responsive Mode --------------*/


@media (max-width: 991px) {
  .footer-content {
    padding: 30px 0 0;
  }

  .footer .contacts {
	padding: 30px 0;
  }
}

@media (max-width: 768px) {
  .footer {
	text-align: center;
  }

  .footer .contacts {
	flex-direction: column;
  }
}