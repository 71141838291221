/*------------- #TABLE OF CONTENTS --------------*/

/*------------------------------------------------------------------

1. Blocks / Styles for Blocks
	1.1 Accordion
	1.2 Buttons
	1.3 Case
	1.4 Checkboxes
	1.5 Clients
	1.6 Contacts
	1.7 Counters
	1.8 Heading
	1.9 Info Boxes
	1.10 Lists
	1.11 Maps
	1.12 Navigation Search
	1.13 Navigation Shop Cart
	1.14 Pie Charts
	1.15 Pricing Tables
	1.16 Radio Buttons
	1.17 Services
	1.18 Skills
	1.19 Sliders
	1.20 Socials
	1.21 Tabs
	1.22 Teammembers
	1.23 Testimonials

2. Layouts / Styles for layouts
	2.1 Footer
	2.2 Header
	2.3 Top Bar

3. Plugins / Styles for plugins
	3.1 Navigation
	3.2 Leaflet
	3.3 Select2
	3.4 Swiper

4. Theme Styles / Main Theme Styles
	4.1 Blogs
	4.2 Breadcrumbs
	4.3 Cart Main
	4.4 Checkout
	4.5 Comments
	4.6 Custom Variables
	4.7 Forms
	4.8 Global
	4.9 Helper
	4.10 Page Navigation
	4.11 Popup Cart
	4.12 Popup Search
	4.13 Product Reviews
	4.14 Rait Stars
	4.15 Root
	4.16 Row Masonry
	4.17 Shop
	4.18 Stunning Header
	4.19 Subscribe Section
	4.20 Tables
	4.21 Typography
	4.22 User Menu
	4.23 WP Core

5. Vendors / Bootstrap

6. Widgets / Styles for all Widgets
	6.1 Widget Contacts
	6.2 Widget Follow
	6.3 Widget Info
	6.4 Widget Latest News
	6.5 Widget Links
	6.6 Widget Popular Tags
	6.7 Widget Post Category
	6.8 Widget Search
	6.9 Widgets


-------------------------------------------------------------------*/