/*------------- #W-FOLLOW --------------*/


.follow-item {
  display: flex;
  align-items: center;
  color: var(--white-color);
  height: 60px;
  padding: 10px 60px 10px 30px;
  position: relative;
  font-size: 16px;
  font-weight: 600;

  &:hover {
	opacity: .8;
	color: var(--white-color);
  }

  & > .crumina-icon {
	margin-right: 20px;
  }

  .crumina-icon {
	fill: currentColor;
  }

  .check {
	opacity: 0;
  }

  &.active {
	.check {
	  opacity: 1;
	}

	.add {
	  opacity: 0;
	}
  }
}

.w-follow-add {
  height: 100%;
  width: 60px;
  background-color: rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0;
  right: 0;

  .crumina-icon {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
  }
}

.follow-wrap--inline {
  display: flex;
  align-items: center;

  .follow-item {
	width: 100%;
	justify-content: center;
	padding: 10px 30px;
  }
}


/*------------- Responsive Mode --------------*/

@media (max-width: 992px) {
  .follow-wrap--inline {
	flex-wrap: wrap;
  }
}