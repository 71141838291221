/*------------- #VARIABLES --------------*/


// We use these as default colors throughout

$primary-accent-color: #27c7cd !default;
$secondary-accent-color: #6c6ff2 !default;

$heading-color: #1b2c38 !default;
$global-link-color: #1b2c38 !default;
$font-color: #495057 !default;

$theme-shadow: 0 10px 20px 0 rgba(39, 199, 205, 0.2) !default;

$h1-fontsize: 48px !default;
$h2-fontsize: 42px !default;
$h3-fontsize: 36px !default;
$h4-fontsize: 28px !default;
$h5-fontsize: 20px !default;
$h6-fontsize: 16px !default;
$h-font-weight: 700 !default;
$h-margin-bottom: 20px !default;
$h-letter-spacing: .025em !default;
$h-font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;


// We use these to control various global styles

$body-bg-color: #fff !default;
$body-font-size: 16px !default;
$body-font-color: $font-color !default;
$body-font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$body-font-weight: 400 !default;
$body-letter-spacing: initial !default;
$base-line-height: 1.6 !default;


$white-color: #ffffff !default;
$icon-color: #aab3bd !default;

$red-themes: #fc3e59 !default;
$green-themes: #95d133 !default;
$grey-themes: #f1f2ec !default;
$dark-themes: #1b2c38 !default;
$yellow-themes: #ffcb05 !default;
$brown-themes: #d89961 !default;
$pink-themes: #f28089 !default;
$blue-themes: #313759 !default;

$input-dark: #333d44 !default;


$border-grey-color: #DAE0E8 !default;
$border-dark-color: #262d37 !default;



//socials colors
$c-rss: #f4b459 !default;
$c-mail: #996DD1 !default;
$c-pinterest: #cc2127 !default;
$c-googleplus: #dd4b39 !default;
$c-google: #dd4b39 !default;
$c-facebook: #2f5b9d !default;
$c-twitter: #38bff1 !default;
$c-amazone: #F69B06 !default;
$c-behance: #2D2D2D !default;
$c-bing: #FFA616 !default;
$c-creative-market: #8DA741 !default;
$c-deviantart: #1B1B1B !default;
$c-dribbble: #f74881 !default;
$c-dropbox: #0BA4E0 !default;
$c-envato: #6D9C3E !default;
$c-flickr: #26A9E1 !default;
$c-instagram: #E75351 !default;
$c-kickstarter: #14E06E !default;
$c-linkedin: #4A8F9E !default;
$c-medium: #00E56B !default;
$c-periscope: #3FA4C4 !default;
$c-quora: #F85F62 !default;
$c-reddit: #F05825 !default;
$c-shutterstock: #008EC0 !default;
$c-skype: #00AAF1 !default;
$c-snapchat: #FFED45 !default;
$c-soundcloud: #FF3300 !default;
$c-spotify: #1ED760 !default;
$c-trello: #0079BF !default;
$c-tumblr: #36465D !default;
$c-vimeo: #1AB7EA !default;
$c-vk: #6383A8 !default;
$c-whatsapp: #25D366 !default;
$c-wikipedia: #000000 !default;
$c-wordpress: #21759B !default;
$c-youtube: #CD201F !default;
$c-messanger: #3b84f6 !default;
$c-telegram: #3d87c4 !default;
$c-email: #fd4e26 !default;
