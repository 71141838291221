/*------------- #TESTIMONIALS --------------*/


.crumina-testimonial-item {
  .testimonial-text {
	margin-bottom: 30px;
	font-weight: 300;
  }

  .post-author-name {
	font-size: 18px;
  }

  .author-prof {
	font-size: 16px;
  }
}

.author-quote-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}


/*------------- #TESTIMONIALS ARROWS --------------*/


.testimonial-item-arrow {
  padding: 50px 60px 10px;
  background-color: var(--white-color);
  margin-bottom: 40px;
  position: relative;

  .post-author .author-avatar {
	width: initial;
	height: initial;
	min-width: initial;
	min-height: initial;
	margin-right: 30px;
	background-color: transparent;
  }

  .author-prof {
	color: var(--primary-accent-color);
  }

  &:before {
	content: "";
	position: absolute;
	bottom: -40px;
	right: 0;
	border-width: 40px 40px 0 0;
	border-style: solid;
	border-color: #e8e9e1 transparent transparent #e8e9e1;
	display: block;
	width: 0; /* Rules for Firefox 3.0 and better */
  }

  &:after {
	content: "";
	position: absolute;
	bottom: -40px;
	left: 0;
	right: 40px;
	height: 40px;
	width: calc(100% - 40px);
	background-color: inherit;
  }
}


/*------------- #TESTIMONIALS AUTHOR TOP --------------*/

.testimonial-item-author-top {
  text-align: center;

  .author-avatar {
	width: 120px;
	height: 120px;
	border: 4px solid var(--white-color);
	border-radius: 100%;
	margin: 0 auto 50px;
	background-color: var(--grey-themes);
  }

  .testimonial-text {
	color: var(--white-color);
	margin-bottom: 20px;
  }

  .post-author {
	text-align: center;
	justify-content: center;
  }

  .post-author-name {
	color: var(--yellow-themes);

	&:hover {
	  color: var(--primary-accent-color);
	}
  }

  .author-prof {
	color: #949fac;
  }
}


/*------------- #TESTIMONIALS AUTHOR BOTTOM --------------*/

.testimonial-item-author-bottom {
  text-align: center;

  .post-author {
	justify-content: center;
  }

  .author-avatar {
	width: initial;
	height: initial;
	min-width: initial;
	min-height: initial;
	margin-right: 30px;
	background-color: transparent;
  }

  .author-prof {
	color: var(--primary-accent-color);
  }

  .testimonial-text {
	font-style: italic;
  }
}


/*------------- #TESTIMONIALS MODERN --------------*/

.testimonial-item-modern {
  background-color: var(--dark-themes);
  border-radius: 100px 0 100px 100px;
  padding: 40px 80px;
  margin: 0 30px 0 50px;
  position: relative;

  &:after {
	content: '';
	display: block;
	position: absolute;
	top: 0;
	right: -30px;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 30px 30px 0 0;
	border-color: var(--dark-themes) transparent transparent transparent;

	@media (max-width: 570px) {
	  display: none;
	}
  }

  @media (max-width: 570px) {
	padding: 70px 30px 20px;
	border-radius: 40px;
	margin: 50px 0 0;
  }

  &.author-arrow-reverse {
	border-radius: 0 100px 100px 100px;
	margin: 0 50px 0 30px;

	@media (max-width: 570px) {
	  padding: 70px 30px 20px;
	  margin: 50px 0 0;
	  border-radius: 40px;
	}

	.author-avatar {
	  left: auto;
	  right: -50px;

	  @media (max-width: 570px) {
		top: -50px;
		left: 50%;
		margin-left: -50px;
	  }
	}

	&:after {
	  left: -30px;
	  right: auto;
	  border-width: 0 30px 30px 0;
	  border-color: transparent var(--dark-themes) transparent transparent;
	}
  }

  .testimonial-content {
	position: relative;
	z-index: 6;
  }

  .testimonial-text {
	color: var(--white-color);
	font-style: italic;
	margin-bottom: 0;
  }

  .author-quote-wrap {
	align-items: flex-end;
  }

  .post-author-name {
	color: var(--yellow-themes);
  }

  .author-prof {
	color: #949fac;
  }

  .author-avatar {
	position: absolute;
	top: 0;
	left: -50px;
	border-radius: 100%;
	width: 100px;
	height: 100px;
	background-color: var(--grey-themes);
	display: flex;

	@media (max-width: 570px) {
	  top: -50px;
	  left: 50%;
	  margin-left: -50px;
	}

	img {
	  margin: auto;
	}
  }
}


/*------------- #TESTIMONIALS QUOTE LEFT --------------*/

.testimonial-item-quote-left {
  display: flex;

  .quote {
	width: 100px;
	height: 71px;
	min-width: 100px;
	min-height: 71px;
	margin-right: 30px;
  }

  .testimonial-text {
	font-style: italic;
  }

  .post-author-name {
	color: var(--primary-accent-color);
  }

  .author-avatar {
	width: initial;
	height: initial;
	min-width: initial;
	min-height: initial;
	margin-right: 30px;
	border-radius: 0;
	background-color: transparent;
  }
}


/*------------- Responsive Mode --------------*/


@media (max-width: 768px) {
  .testimonial-item-arrow {
	padding: 20px 30px 10px;
  }

  .testimonial-item-quote-left {
	flex-direction: column;
  }

  .testimonial-item-quote-left .quote {
	margin-right: 0;
	margin-bottom: 20px;
	margin-top: 0;
  }

  .testimonial-item-quote-left {
	padding-top: 0;
  }
}