/*------------- #TEAMMEMBER --------------*/


.crumina-teammember-item {
	text-align: center;

  .socials .social-item {
	max-width: 20px;
	max-height: 20px;
	margin-right: 15px;

	&:last-child {
	  margin-right: 0;
	}
  }

  .socials {
	margin-top: 20px;
  }
}

.teammember-item-thumb {
  width: 270px;
  height: 270px;
  min-height: 270px;
  min-width: 270px;
  border-radius: 100%;
  background-color: var(--grey-themes);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 30px;
}

.teammember-author-name {
  font-weight: 400;
  margin-bottom: 5px;
}

.teammember-author-prof {
  font-size: 16px;
}



/*------------- Responsive Mode --------------*/


@media (max-width: 1200px) {
  .teammember-item-thumb {
	width: 100px;
	height: 100px;
	min-height: 100px;
	min-width: 100px;
  }
}

@media (max-width: 992px) {
  .crumina-teammember-item .socials {
	margin-top: 15px;
  }

  .teammember-author-name {
	margin-bottom: 15px;
  }

  .teammember-item-thumb {
	margin-bottom: 15px;
  }
}