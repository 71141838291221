/*------------- #NAVIGATION-SEARCH-POPUP --------------*/


.navigation-search {
  cursor: pointer;
  position: relative;

  &:hover {
	.crumina-icon {
	  fill: var(--primary-accent-color);
	}
  }
}