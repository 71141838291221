/*------------- #CRUMINA-CASE-ITEM --------------*/


.crumina-case-item {
  text-align: center;
  background-color: var(--grey-themes);
  transition: all .3s ease;

  &:hover {
	background-color: var(--primary-accent-color);

	.case-item-thumb img {
	  transform: scale(1.15);
	}

	.case-item-cat a,
	.case-item-title {
	  color: var(--white-color);

	  &:hover {
		text-decoration: underline;
	  }
	}
  }

  .case-item-content {
	padding: 25px 40px;
  }

  .case-item-title {
	margin-bottom: 8px;
  }

  .case-item-cat {
	a {
	  color: var(--body-font-color);
	}
  }
}

.case-item-thumb {
  overflow: hidden;
}

.case-item-thumb img {
  transition: all .3s cubic-bezier(0.465, 0.183, 0.153, 0.946);
}

.case-time-create {
  font-size: 16px;
  color: #949fac;
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  .crumina-icon {
	margin-right: 10px;
	fill: #949fac;
  }
}

.case-item-text {
  margin-bottom: 30px;
}

.like-block {
  display: flex;
  align-items: center;
}

.likes {
  display: flex;
  align-items: center;
  color: var(--red-themes);
  margin-left: 30px;
  font-size: 16px;

  .crumina-icon {
	fill: var(--red-themes);
	margin-right: 12px;
  }
}

.seo-results-thumb {
  padding: 0 50px;

  canvas {
	margin-bottom: 30px;
  }
}

.results-points {
  padding-left: 40px;

  .results-points-item {
	align-items: center;
	margin-bottom: 10px;
  }

  .results-point-sircle {
	display: inline-block;
	width: 20px;
	height: 20px;
	min-width: 20px;
	min-height: 20px;
	border-radius: 100%;
	border-color: transparent;
	margin-right: 20px;
  }

  .results-points-item-count {
	font-size: 16px;
	font-weight: 700;
	color: var(--heading-color);
	display: flex;
	align-items: flex-start;

	span {
	  color: var(--body-font-color);
	  font-weight: 400;
	}
  }
}

.seo-results-item-info {
  margin-bottom: 0;
}

.seo-results-item {
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  &:last-of-type {
	margin-bottom: 0;
  }

  .crumina-icon {
	margin-right: 30px;
	max-width: 60px;
  }
}

.seo-results--inline {
  display: flex;
  justify-content: space-between;

  .seo-results-item {
	flex-direction: column;
	margin-bottom: 0;
	justify-content: center;

	&:last-of-type {
	  margin-bottom: 0;
	}

	.crumina-icon {
	  margin-right: 0;
	  margin-bottom: 30px;
	}
  }
}


/*------------- Responsive Mode --------------*/

@media (max-width: 1024px) {
  .seo-results-item-info {
	font-size: 20px;
  }
}

@media (max-width: 768px) {
  .crumina-case-item .case-item-content {
	padding: 15px 20px;
  }

  .seo-results-thumb {
	padding: 0;
  }

  .results-points {
	padding-left: 0;
  }
}

@media (max-width: 420px) {
  .seo-results--inline {
	flex-direction: column;
  }

  .seo-results--inline .seo-results-item {
	margin-bottom: 20px;

	&:last-child {
	  margin-bottom: 0;
	}
  }
}