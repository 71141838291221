/*------------- #W-CONTACTS --------------*/


.w-contacts {

  .contacts-text {
	margin-bottom: 30px;
  }

  .contact-item {
	display: flex;
	align-items: center;
	margin-bottom: 15px;

	.crumina-icon {
	  margin-right: 20px;
	}

	&:last-child {
	  margin-bottom: 0;
	}

	.title {
	  font-size: 24px;
	  font-weight: 500;
	  color: var(--heading-color);
	  margin-bottom: 0;

	  &:hover {
		color: var(--primary-accent-color)
	  }
	}
  }
}