/*------------- #W-POPULAR-TAGS --------------*/



.tags-list {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;

  li {
	display: inline-block;
	margin-right: 10px;
	margin-bottom: 10px;

	&:last-child {
	  margin-right: 0;
	}
  }

  a {
	border-radius: 30px;
	line-height: 1;
	padding: 10px 23px;
	font-size: 16px;
	border: 2px solid #d9dfe7;

	&:hover {
	  background-color: var(--primary-accent-color);
	  border-color: var(--primary-accent-color);
	  color: var(--white-color);
	}
  }
}