/*------------- #STYLES for RADIO buttons --------------*/


.crumina-radio label {
  cursor: pointer;
  padding-left: 30px;
  margin: 0;
  position: relative;
  color: var(--body-font-color);
  font-size: 16px;
}
.crumina-radio label span {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  transition-duration: 0.2s;
  margin-left: 0;
}
.crumina-radio label .circle {
  border: 2px solid var(--primary-accent-color);
  background-color: var(--primary-accent-color);
  height: 18px;
  width: 18px;
  border-radius: 100%;
}
.crumina-radio label .check {
  height: 18px;
  width: 18px;
  border-radius: 100%;
  -webkit-transform: scale3d(0,0,0);
  -moz-transform: scale3d(0,0,0);
  -o-transform: scale3d(0,0,0);
  -ms-transform: scale3d(0,0,0);
  transform: scale3d(0,0,0);
}
.crumina-radio label .check:after {
  display: block;
  position: absolute;
  content: "";
  background-color: rgba(0,0,0, 0.87);
  left: -18px;
  top: -18px;
  height: 50px;
  width: 50px;
  border-radius: 100%;
  z-index: 1;
  opacity: 0;
  margin: 0;
  -webkit-transform: scale3d(1.5,1.5,1);
  -moz-transform: scale3d(1.5,1.5,1);
  -o-transform: scale3d(1.5,1.5,1);
  -ms-transform: scale3d(1.5,1.5,1);
  transform: scale3d(1.5,1.5,1);
}

.crumina-radio input[type=radio] {
  opacity: 0;
  height: 0;
  width: 0;
  overflow: hidden;
  display: none;
}
.crumina-radio input[type=radio]:checked ~ .check, .crumina-radio input[type=radio]:checked ~ .circle {
  opacity: 1;
  border-color: var(--border-grey-color);
}
.crumina-radio input[type=radio]:checked ~ .check {
  background-color: var(--white-color);
}
.crumina-radio input[type=radio]:checked ~ .circle {
  border-color: var(--primary-accent-color);
  background-color: var(--primary-accent-color);
}
.crumina-radio input[type=radio]:checked:disabled ~ .check {
  background-color: #c5ccd5;
  border-color: #d9dfe7;
}

.crumina-radio input[type=radio]:checked:disabled ~ .circle {
  background-color: #d9dfe7;
}

.crumina-radio input[type=radio]:checked ~ .check {
  -webkit-transform: scale3d(0.45, 0.45, 1);
  -moz-transform: scale3d(0.45, 0.45, 1);
  -o-transform: scale3d(0.45, 0.45, 1);
  -ms-transform: scale3d(0.45, 0.45, 1);
  transform: scale3d(0.45, 0.45, 1);
}

.crumina-radio input[type=radio][disabled] ~ .check {
  background-color: transparent;
}
.crumina-radio input[type=radio][disabled] ~ .circle {
  background-color: #d9dfe7;
  border-color: #d9dfe7;
}

@keyframes rippleOn {
  0% {
	opacity: 0;
  }
  50% {
	opacity: 0.2;
  }
  100% {
	opacity: 0;
  }
}
@keyframes rippleOff {
  0% {
	opacity: 0;
  }
  50% {
	opacity: 0.2;
  }
  100% {
	opacity: 0;
  }
}