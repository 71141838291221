/*------------- #HELPERS --------------*/

.align-center,
.aligncenter {
  text-align: center;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.x-large-padding {
  padding: 200px 0;

  @media (max-width: 768px) {
    padding: 80px 0;
  }
}

.large-padding {
  padding: 80px 0;

  @media (max-width: 768px) {
	padding: 40px 0;
  }
}

.medium-padding {
  padding: 60px 0;

  @media (max-width: 768px) {
	padding: 20px 0;
  }
}

.small-padding {
  padding: 40px 0;

  @media (max-width: 768px) {
	padding: 15px 0;
  }
}

.large-padding-bottom {
  padding-bottom: 80px;

  @media (max-width: 768px) {
    padding-bottom: 40px;
  }
}

.large-padding-top {
  padding-top: 80px;

  @media (max-width: 768px) {
	padding-top: 40px;
  }
}

.lagre-padding-bottom {
  padding-bottom: 120px;

  @media (max-width: 768px) {
	padding-bottom: 60px;
  }
}

.medium-padding-top {
  padding-top: 60px;

  @media (max-width: 768px) {
	padding-top: 30px;
  }
}

.medium-padding-bottom {
  padding-bottom: 60px;

  @media (max-width: 768px) {
	padding-bottom: 30px;
  }
}

.small-padding-top {
  padding-top: 40px;

  @media (max-width: 768px) {
	padding-top: 20px;
  }
}

.small-padding-bottom {
  padding-bottom: 40px;

  @media (max-width: 768px) {
	padding-bottom: 20px;
  }
}

.medium-negative-margin-top {
  margin-top: -40px;

  @media (max-width: 992px) {
    margin-top: -20px;
  }
}

.c-white {
  color: var(--white-color)
}

.c-yellow-themes {
  color: var(--yellow-themes);
}

.c-red-themes {
  color: var(--red-themes);
}

.fill-red-themes {
  fill: var(--red-themes);
}

.c-green-themes {
  color: var(--green-themes);
}

.c-dark {
  color: var(--heading-color);
}

.fill-green-themes {
  fill: var(--green-themes);
}

.c-primary-themes {
  color: var(--primary-accent-color);
}

.c-secondary-themes {
  color: var(--secondary-accent-color);
}

.fill-primary-themes {
  fill: var(--primary-accent-color);
}

.bg-grey-theme {
  background-color: var(--grey-themes);
}

.bg-dark-theme {
  background-color: var(--dark-themes);
}

.bg-green-themes {
  background-color: var(--green-themes);
}

.bg-red-themes {
  background-color: var(--red-themes);
}

.bg-yellow-themes {
  background-color: var(--yellow-themes);
}

.bg-primary-themes {
  background-color: var(--primary-accent-color);
}

.bg-secondary-themes {
  background-color: var(--secondary-accent-color);
}
.dark-section {
  background-color: #181e23;
  color: lighten( $font-color, 30 );

  a {
    color: var(--white-color);
  }
}


.bg-mountains {
  background-image: url("../img/demo-content/illustrations/img25.png");
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
}

.crumina-icon {
  transition: all .3s ease;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(17, 17, 17, 0.3);
  transition: all 0.4s ease;
}

.pt-200 {
  padding-top: 200px;
}
.pb-200 {
  padding-bottom: 200px;
}

.page-404-title {
  font-size: 140px;
  color: var(--primary-accent-color);
  font-weight: 900;
  line-height: 1;
}

.weight-black {
  font-weight: 900;
}

.link-with-icon {
  display: flex;
  align-items: center;
  font-size: 16px;

  .crumina-icon {
    margin-left: 10px;
  }

  &:hover {
    .crumina-icon {
      transform: translateX(3px);
    }
  }
}

.fs-18 {
  font-size: 18px;
}

.fs-16 {
  font-size: 16px;
}

.facebook-bg-color {
  background-color: #3a589b;
}

.twitter-bg-color {
  background-color: #08acee;
}

.linkedin-bg-color {
  background-color: #007bb6;
}

.pinterest-bg-color {
  background-color: #ca2127;
}

.vc-bg-color {
  background-color: #367ab1;
}

.gmail-bg-color {
  background-color: #d7503f;
}

body {
  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(47, 44, 44, .85);
    opacity: 0;
    transition: opacity .3s ease;
    z-index: -999;
  }

  &.overlay-enable:before {
    opacity: .8;
    z-index: 500;
  }
}

.main-content-wrapper {
  overflow-x: hidden;
}

/*------------- /Styles for animation sections and elements --------------*/

.icon-demonstration {
  text-align: center;
  margin-bottom: 30px;
  padding: 20px;
  background-color: rgba($primary-accent-color, .15);
  border-radius: 20px;

  .crumina-icon {
    fill: var(--primary-accent-color);
    margin-bottom: 20px;
  }

  pre {
    font-size: 12px;
    color: var(--red-themes);
  }

  code {
    white-space: initial;
    line-height: 2;
  }
}

/*------------- Styles for animation sections and elements --------------*/

.section-anime-js {
  position: relative;
  overflow: hidden;
  opacity: 0;
}

.js-animate-icon .crumina-icon path {
  opacity: 0;
}

.element-anime-opacity-js,
.element-anime-fadeInUp-js {
  opacity: 0;
}

/*------------- Responsive Mode --------------*/


@media (max-width: 768px) {
  .page-404-title {
    font-size: 100px;
  }
}