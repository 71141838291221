/*------------- #NAVIGATION-SHOP-CART --------------*/


.navigation-shop-cart {
  position: relative;
  cursor: pointer;

  &:hover .crumina-icon {
    fill: var(--primary-accent-color);
  }
}

.cart-count {
  display: block;
  position: absolute;
  left: -6px;
  top: 4px;
  min-width: 16px;
  min-height: 16px;
  line-height: 14px;
  font-size: 10px;
  color: var(--white-color);
  background-color: var(--primary-accent-color);
  text-align: center;
  border: 1px solid transparent;
  border-radius: 50%;
  padding: 0 2px;
}

.navigation-shop-cart-wrapper {
  position: relative;
}

.shop-link-responsive {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  display: none;
}


/*--------------------
    MEDIA QUERIES
--------------------*/

@media (max-width: 992px) {
  .shop-link-responsive {
    display: block;
  }
}