/*------------- #BUTTONS --------------*/

.crumina-button {
  position: relative;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  border-radius: 50px;
  padding: 5px 10px;
  font-size: 14px;
  text-align: center;
  line-height: 1;
  font-weight: 700;
  color: var(--white-color);
  outline: none;
  border: 2px solid;
  transition: all .3s ease;

  &:after {
	content: '';
	display: block;
	border-radius: 50px;
	position: absolute;
	top: -2px;
	left: -2px;
	width: calc(100% + 4px);
	height: calc(100% + 4px);
	box-shadow: inset 0px -4px 0px 0px rgba(0, 0, 0, 0.2);
	opacity: 0;
	transition: all .3s ease;
  }

  & + .btn {
	margin-left: 30px;
  }

  &:hover {
	outline: none;
	text-decoration: none;

	&:after {
	  opacity: 1;
	}
  }

  &:focus {
	outline: none;
  }

  &:active {
	outline: none;

	&:after {
	  box-shadow: inset 0 4px 0 0 rgba(0, 0, 0, 0.2);
	}
  }

  &:disabled {
	background-color: var(--grey-themes) !important;
	color: var(--grey-themes) !important;
	border-color: var(--grey-themes) !important;
	cursor: not-allowed;
  }
}

button {
  cursor: pointer;
  background-color: transparent;
  border: none;
  box-shadow: none;
  padding: 0;
}

.button--xs {
  padding: .375rem 1rem;
}

.button--s {
  padding: .688rem 1.5rem;
}

.button--m {
  padding: 1rem 2rem;
}

.button--l {
  padding: 1.25rem 2.5rem;
}

.button--with-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  .crumina-icon, i {
	transition: all .3s ease;
	position: relative;
  }
}

.button--icon-left {
  .crumina-icon, i {
	margin-right: 10px;
  }
}

.button--icon-right {
  .crumina-icon, i {
	margin-left: 10px;
  }
}

.load-more-button {
  border: 3px solid #d9dfe7;
  color: #949fac;
  margin-top: 60px;
}

.universal-btn-wrapper {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: -20px;

  & > * {
	margin-right: 20px;
	margin-bottom: 20px;

	&:last-child {
	  margin-right: 0;
	}
  }
}

/*------------- #Color-scheme for buttons --------------*/


.button--primary {
  background-color: var(--primary-accent-color);
  border-color: var(--primary-accent-color);
  color: var(--white-color);

  &:hover {
	color: var(--white-color);
  }

  .crumina-icon,
  i {
	color: var(--white-color);
	fill: var(--white-color);
  }

  &.button--bordered {
	color: var(--primary-accent-color);

	.crumina-icon,
	i {
	  color: var(--primary-accent-color);
	  fill: var(--primary-accent-color);
	}

	&:hover {
	  color: var(--white-color);
	  border-color: var(--primary-accent-color);
	  background-color: var(--primary-accent-color);

	  .crumina-icon,
	  i {
		color: var(--white-color);
		fill: var(--white-color);
	  }
	}
  }
}

.button--secondary {
  background-color: var(--secondary-accent-color);
  border-color: var(--secondary-accent-color);
  color: var(--white-color);

  &:hover {
	color: var(--white-color);
  }

  .crumina-icon,
  i {
	color: var(--white-color);
	fill: var(--white-color);
  }

  &.button--bordered {
	color: var(--secondary-accent-color);

	.crumina-icon,
	i {
	  color: var(--secondary-accent-color);
	  fill: var(--secondary-accent-color);
	}

	&:hover {
	  color: var(--white-color);
	  border-color: var(--secondary-accent-color);
	  background-color: var(--secondary-accent-color);

	  .crumina-icon,
	  i {
		color: var(--white-color);
		fill: var(--white-color);
	  }
	}
  }
}

.button--dark {
  background-color: var(--dark-themes);
  border-color: var(--dark-themes);
  color: var(--white-color);

  &:hover {
	color: var(--white-color);
  }

  .crumina-icon,
  i {
	color: var(--white-color);
	fill: var(--white-color);
  }

  &.button--bordered {
	color: var(--dark-themes);

	.crumina-icon,
	i {
	  color: var(--dark-themes);
	  fill: var(--dark-themes);
	}

	&:hover {
	  color: var(--white-color);
	  border-color: var(--dark-themes);
	  background-color: var(--dark-themes);

	  .crumina-icon,
	  i {
		color: var(--white-color);
		fill: var(--white-color);
	  }
	}
  }
}

.button--red {
  background-color: var(--red-themes);
  border-color: var(--red-themes);
  color: var(--white-color);

  &:hover {
	color: var(--white-color);
  }

  .crumina-icon,
  i {
	color: var(--white-color);
	fill: var(--white-color);
  }

  &.button--bordered {
	color: var(--red-themes);

	.crumina-icon,
	i {
	  color: var(--red-themes);
	  fill: var(--red-themes);
	}

	&:hover {
	  color: var(--white-color);
	  border-color: var(--red-themes);
	  background-color: var(--red-themes);

	  .crumina-icon,
	  i {
		color: var(--white-color);
		fill: var(--white-color);
	  }
	}
  }
}

.button--yellow {
   background-color: var(--yellow-themes);
   border-color: var(--yellow-themes);
   color: var(--white-color);

   &:hover {
	 color: var(--white-color);
   }

   .crumina-icon,
   i {
	 color: var(--white-color);
	 fill: var(--white-color);
   }

   &.button--bordered {
	 color: var(--yellow-themes);

	 .crumina-icon,
	 i {
	   color: var(--yellow-themes);
	   fill: var(--yellow-themes);
	 }

	 &:hover {
	   color: var(--white-color);
	   border-color: var(--yellow-themes);
	   background-color: var(--yellow-themes);

	   .crumina-icon,
	   i {
		 color: var(--white-color);
		 fill: var(--white-color);
	   }
	 }
   }
 }

.button--green {
  background-color: var(--green-themes);
  border-color: var(--green-themes);
  color: var(--white-color);

  &:hover {
	color: var(--white-color);
  }

  .crumina-icon,
  i {
	color: var(--white-color);
	fill: var(--white-color);
  }

  &.button--bordered {
	color: var(--green-themes);

	.crumina-icon,
	i {
	  color: var(--green-themes);
	  fill: var(--green-themes);
	}

	&:hover {
	  color: var(--white-color);
	  border-color: var(--green-themes);
	  background-color: var(--green-themes);

	  .crumina-icon,
	  i {
		color: var(--white-color);
		fill: var(--white-color);
	  }
	}
  }
}

.button--grey {
  background-color: var(--grey-themes);
  border-color: var(--grey-themes);
  color: var(--body-font-color);

  &:hover {
	color: var(--body-font-color);
  }

  .crumina-icon,
  i {
	color: var(--body-font-color);
	fill: var(--body-font-color);
  }

  &.button--bordered {
	color: var(--body-font-color);

	.crumina-icon,
	i {
	  color: var(--body-font-color);
	  fill: var(--body-font-color);
	}

	&:hover {
	  color: var(--body-font-color);
	  border-color: var(--grey-themes);
	  background-color: var(--grey-themes);

	  .crumina-icon,
	  i {
		color: var(--body-font-color);
		fill: var(--body-font-color);
	  }
	}
  }
}

.button--brown {
  background-color: var(--brown-themes);
  border-color: var(--brown-themes);
  color: var(--white-color);

  &:hover {
	color: var(--white-color);
  }

  .crumina-icon,
  i {
	color: var(--white-color);
	fill: var(--white-color);
  }

  &.button--bordered {
	color: var(--brown-themes);

	.crumina-icon,
	i {
	  color: var(--brown-themes);
	  fill: var(--brown-themes);
	}

	&:hover {
	  color: var(--white-color);
	  border-color: var(--brown-themes);
	  background-color: var(--brown-themes);

	  .crumina-icon,
	  i {
		color: var(--white-color);
		fill: var(--white-color);
	  }
	}
  }
}

.button--pink {
  background-color: var(--pink-themes);
  border-color: var(--pink-themes);
  color: var(--white-color);

  &:hover {
	color: var(--white-color);
  }

  .crumina-icon,
  i {
	color: var(--white-color);
	fill: var(--white-color);
  }

  &.button--bordered {
	color: var(--pink-themes);

	.crumina-icon,
	i {
	  color: var(--pink-themes);
	  fill: var(--pink-themes);
	}

	&:hover {
	  color: var(--white-color);
	  border-color: var(--pink-themes);
	  background-color: var(--pink-themes);

	  .crumina-icon,
	  i {
		color: var(--white-color);
		fill: var(--white-color);
	  }
	}
  }
}

.button--blue {
  background-color: var(--blue-themes);
  border-color: var(--blue-themes);
  color: var(--white-color);

  &:hover {
	color: var(--white-color);
  }

  .crumina-icon,
  i {
	color: var(--white-color);
	fill: var(--white-color);
  }

  &.button--bordered {
	color: var(--blue-themes);

	.crumina-icon,
	i {
	  color: var(--blue-themes);
	  fill: var(--blue-themes);
	}

	&:hover {
	  color: var(--white-color);
	  border-color: var(--blue-themes);
	  background-color: var(--blue-themes);

	  .crumina-icon,
	  i {
		color: var(--white-color);
		fill: var(--white-color);
	  }
	}
  }
}

.button--white {
  background-color: var(--white-color);
  border-color: var(--white-color);
  color: var(--dark-themes);

  &:hover {
	color: var(--dark-themes);
  }

  .crumina-icon,
  i {
	color: var(--dark-themes);
	fill: var(--dark-themes);
  }

  &.button--bordered {
	color: var(--white-color);

	.crumina-icon,
	i {
	  color: var(--white-color);
	  fill: var(--white-color);
	}

	&:hover {
	  color: var(--dark-themes);
	  border-color: var(--white-color);
	  background-color: var(--white-color);

	  .crumina-icon,
	  i {
		color: var(--dark-themes);
		fill: var(--dark-themes);
	  }
	}
  }
}

.button--bordered {
  background-color: transparent;
}

/*------------- #Button back-to-top --------------*/

.back-to-top {
  position: fixed;
  z-index: 999;
  width: 50px;
  height: 50px;
  display: block;
  fill: #20282f;
  stroke: inherit;
  bottom: 50px;
  right: 50px;
  opacity: 0.5;
  cursor: pointer;
  transition: all .3s ease;

  .crumina-icon {
	width: 50px;
	height: 50px;
  }

  &:hover {
	opacity: 1;
	fill: var(--primary-accent-color);
  }
}


/*------------- Responsive Mode --------------*/


@media (max-width: 768px) {


  .button--s {
	font-size: 12px;
	padding: .2rem 1rem;
  }

  .button--m {
	font-size: 12px;
	padding: .5rem 1.4rem;
  }

  .button--l {
	font-size: 12px;
	padding: .6rem 1.5rem;
  }

  .button--icon-left {
	.crumina-icon,
	i {
	  margin-right: 7px;
	}
  }

  .button--icon-right {
	.crumina-icon,
	i {
	  margin-left: 7px;
	}
  }
}