/*------------- #TABLES --------------*/


table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  max-width: 100%;
  margin: 35px 0;
  border-radius: 3px;
  border: 1px solid var(--border-grey-color);
}

table thead {
  background-color: #f1f4f9;
}

table * {
  transition: all .3s ease;
}

table > tbody > tr {

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1),inset 4px 0 0 0 rgba(100, 25, 255, 0.004);

    & > td:first-child {
      &:before {
        opacity: 1;
      }
    }
  }
}

table > thead > tr > th,
table > tbody > tr > th,
table > tfoot > tr > th,
table > thead > tr > td,
table > tbody > tr > td,
table > tfoot > tr > td {
  padding: 13px 20px;
  line-height: 1.42857143;
  vertical-align: middle;
  border: 1px solid var(--border-grey-color);
}

table > tbody > tr > td:first-child {
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 3px;
    height: 100%;
    background-color: var(--primary-accent-color);
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    transition: all .3s ease;
  }
}

table > thead > tr > th {
  vertical-align: middle;
  color: var(--heading-color);
  text-align: left;
}

table > tbody + tbody {
  border-top: 2px solid var(--border-grey-color);
}

table table {
  background-color: var(--white-color);
}

table col[class*="col-"] {
  position: static;
  display:table-column;
  float: none;
}

table td[class*="col-"],
table th[class*="col-"] {
  position: static;
  display:table-cell;
  float: none;
}

table > thead > tr > td.active,
table > tbody > tr > td.active,
table > tfoot > tr > td.active,
table > thead > tr > th.active,
table > tbody > tr > th.active,
table > tfoot > tr > th.active,
table > thead > tr.active > td,
table > tbody > tr.active > td,
table > tfoot > tr.active > td,
table > thead > tr.active > th,
table > tbody > tr.active > th,
table > tfoot > tr.active > th {
  background-color: #f5f5f5;
}

table > thead > tr > td.success,
table > tbody > tr > td.success,
table > tfoot > tr > td.success,
table > thead > tr > th.success,
table > tbody > tr > th.success,
table > tfoot > tr > th.success,
table > thead > tr.success > td,
table > tbody > tr.success > td,
table > tfoot > tr.success > td,
table > thead > tr.success > th,
table > tbody > tr.success > th,
table > tfoot > tr.success > th {
  background-color: #dff0d8;
}

table > thead > tr > td.info,
table > tbody > tr > td.info,
table > tfoot > tr > td.info,
table > thead > tr > th.info,
table > tbody > tr > th.info,
table > tfoot > tr > th.info,
table > thead > tr.info > td,
table > tbody > tr.info > td,
table > tfoot > tr.info > td,
table > thead > tr.info > th,
table > tbody > tr.info > th,
table > tfoot > tr.info > th {
  background-color: #d9edf7;
}

table > thead > tr > td.warning,
table > tbody > tr > td.warning,
table > tfoot > tr > td.warning,
table > thead > tr > th.warning,
table > tbody > tr > th.warning,
table > tfoot > tr > th.warning,
table > thead > tr.warning > td,
table > tbody > tr.warning > td,
table > tfoot > tr.warning > td,
table > thead > tr.warning > th,
table > tbody > tr.warning > th,
table > tfoot > tr.warning > th {
  background-color: #fcf8e3;
}

table > thead > tr > td.danger,
table > tbody > tr > td.danger,
table > tfoot > tr > td.danger,
table > thead > tr > th.danger,
table > tbody > tr > th.danger,
table > tfoot > tr > th.danger,
table > thead > tr.danger > td,
table > tbody > tr.danger > td,
table > tfoot > tr.danger > td,
table > thead > tr.danger > th,
table > tbody > tr.danger > th,
table > tfoot > tr.danger > th {
  background-color: #f2dede;
}

/*================= Responsive Mode ============*/

@media (max-width: 768px) {
  table > thead > tr > th,
  table > tbody > tr > th,
  table > tfoot > tr > th,
  table > thead > tr > td,
  table > tbody > tr > td,
  table > tfoot > tr > td {
    padding: 15px;
  }

  table {
    border-radius: 10px;
    margin: 20px 0;
  }
}

@media (max-width: 480px) {
  table > thead > tr > th,
  table > tbody > tr > th,
  table > tfoot > tr > th,
  table > thead > tr > td,
  table > tbody > tr > td,
  table > tfoot > tr > td {
    padding: 5px;
  }

  table {
    font-size: 10px;
  }
}