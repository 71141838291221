/*------------- #POPUP SEARCH --------------*/

.popup-search-popup {
  .modal-dialog {
	max-width: initial;
	margin: 1.75rem auto;
  }

  .modal-content {
	background-color: transparent;
	border: none;
  }
}

.search-popup-form {
  position: relative;
  width: 100%;

  .search-popup-input {
	background-color: transparent;
	padding: 0 0 15px;
	border: none;
	border-bottom: 4px solid var(--body-font-color);
	color: #949fac;
	font-size: 24px;
	border-radius: 0;
	transition: all .3s ease;

	&::-webkit-input-placeholder {
	  color: #949fac;
	  opacity: 1;
	}

	&::-moz-placeholder {
	  color: #949fac;
	  opacity: 1;
	}

	/* Firefox 19+ */
	&:-moz-placeholder {
	  color: #949fac;
	  opacity: 1;
	}

	/* Firefox 18- */
	&:-ms-input-placeholder {
	  color: #949fac;
	  opacity: 1;
	}

	&:hover {
	  border-bottom-color: var(--white-color);
	  color: var(--body-font-color);

	  & + .search-popup-close {

		.crumina-icon {
		  fill: var(--body-font-color);
		}
	  }
	}

	&:focus {
	  border-bottom-color:var(--primary-accent-color);
	  color: var(--white-color);
	  box-shadow: none;

	  & + .search-popup-close {

		.crumina-icon {
		  fill: var(--primary-accent-color);
		}
	  }
	}
  }
}

.search-popup-close {
  width: 20px;
  height: 25px;
  position: absolute;
  display: block;
  right: 0;
  top: 5px;
  opacity: 1;

  .crumina-icon {
	fill: #626a76;
  }

  &:hover {
	.crumina-icon {
	  fill: var(--primary-accent-color);
	}
  }
}

.search-popup-enter {
  width: 35px;
  height: 35px;
  position: absolute;
  right: 50px;
  top: 5px;

  .crumina-icon {
	fill: #626a76;
  }

  &:hover {
	.crumina-icon {
	  fill: var(--primary-accent-color);
	}
  }
}

.close {
  cursor: pointer;
}