/*------------- #W-LATEST-NEWS --------------*/


.latest-news-item {
  margin-bottom: 20px;
  border-bottom: 1px solid #d9dfe7;

  .post-time-reading {
	margin-bottom: 15px;
	color: #949fac;
  }

  .crumina-icon {
	fill: #949fac;
  }

  .post-title {
	margin-bottom: 15px;
  }

  .post-author {
	margin-bottom: 20px;
  }

  .post-text {
	margin-bottom: 20px;
	font-size: 16px;
  }
}