/*------------- #COMMENTS --------------*/

.comments {
  margin-top: 40px;

  .crumina-heading {
    margin-bottom: 40px;
  }
}

.comments__author-item {
  display: flex;
  align-items: center;
}

.comment-content {
  p {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }

    a {
      text-decoration: underline;
      text-decoration-color: var(--primary-accent-color);
    }
  }
}

.comment-reply {
  margin-right: 30px;

  .crumina-icon {
    fill: #a1aab6;
  }

  &:hover {
    .crumina-icon {
      fill: var(--primary-accent-color);
    }
  }
}

.comments__list {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;

  .crumina-heading {
    margin-bottom: 60px;
  }

  .comments__avatar {
    margin-right: 30px;
    min-width: 70px;
    min-height: 70px;
    width: 70px;
    height: 70px;
    background-color: var(--grey-themes);
    border-radius: 100%;
  }

  .comments__body {
    margin-bottom: 20px;
  }

  .comments__author {
    font-style: normal;
    margin-bottom: 5px;
    display: block;
  }

  .comments__header {
    display: flex;
    align-items: center;
  }

  .comments__time {

    a {

      &:hover {
        color: var(--primary-accent-color);
      }
    }
  }
}

.comments__item {
  padding-left: 0;
  list-style: none;

  .children {
    list-style: none;
    padding-left: 0;

    .comments__article {
      padding-left: 50px;
    }

    .children {

      .comments__article {
        padding-left: 100px;
      }
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.comments__article {
  padding: 30px 0;
  border-bottom: 1px solid #d9dfe7;
}

.comments__time {
  font-size: 14px;
  color: #949fac;

  a {
    color: var(--body-font-color);

    &:hover {
      color: var(--primary-accent-color);
    }
  }
}

.leave-reply {
  padding: 100px;
}

/*================= Responsive Mode ============*/

@media (max-width: 992px) {
  .leave-reply {
    padding: 20px;
  }

  .comments__item .children .comments__article {
    padding-left: 30px;
  }

  .comments__item .children .children .comments__article {
    padding-left: 60px;
  }
}
