/*------------- #W-INFO --------------*/


.w-info {
  .widget-text {
	margin-bottom: 20px;
  }

  .site-logo {
	margin-bottom: 20px;
  }
}