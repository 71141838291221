/*------------- #MAPS --------------*/


.crumina-map {
  position: relative;
  overflow: hidden;
  max-height: 80vh;
}

.height-500 {
  height: 500px;
  max-height: 80vh;
}